<div id="user-organizations" class="column center fill-space">
  <div class="logo"><img src="assets/images/logo-vertical.svg" /></div>
  <div class="title">My Organizations</div>
  <div class="subtitle">Please select an organization</div>
  <ng-container *ngIf="user$ | async as user">
    <div class="back-button" [routerLink]="['/organization', user.favoriteOrganization]" *ngIf="user.resolvedOrganizationsWithRole.length">
      <div class="back-button-image center"><img src="assets/images/back.svg" /></div>
      <div class="back-button-text center">Back</div>
    </div>

    <div class="organizations-content center wrap">
      <organization-panel
        *ngFor="let organization of user.resolvedOrganizationsWithRole"
        [organization]="organization"
        [activeOrganizationId]="user.favoriteOrganization"
        (click)="changeOrganization(organization!.id)">
      </organization-panel>
      <ng-container class="invitations center wrap" *ngIf="invitations$ | async as invitations">
        <invitation-panel *ngFor="let i of invitations" [invitation]="i"></invitation-panel>
      </ng-container>
    </div>
    <div class="organizations-empty column" *ngIf="!user.resolvedOrganizationsWithRole.length">
      <div class="organizations-empty-icon center"><img src="assets/images/organization.svg" /></div>
      <div class="organizations-empty-text">
        Your account is not associated to an organization. <br />
        Ask your company's administrator to invite you or create a new organization.
      </div>
      <div class="organization-empty-credits gap-1" *ngIf="!user.welcomeCreditsOrganization">
        <div class="organization-empty-credits-icon center"><img src="assets/images/credits.svg" /></div>
        <div class="organizations-empty-credits-text row-center-left">
          Create your first organization and get 100 credits for free.
        </div>
      </div>
    </div>
  </ng-container>

  <div class="buttons column center">
    <div class="button button-action center" (click)="showCreateOrganization()">Create A New Organization</div>
    <div class="logout-link center" (click)="signOut()">Sign Out</div>
  </div>
</div>
