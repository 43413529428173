import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { EFilterType } from '../../model/enum/EFilterType';
import {BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map, Observable, of, startWith} from 'rxjs';
import { ITag } from '../../../../../model/ITag';
import { IFilter, IFilterOption } from '../../model/IFilter';
import { FiltersService } from '../../services/filters/filters.service';
import {FormControl} from "@angular/forms";
import {IUser} from "../../../../../model/IUser";

@Component({
  selector: 'filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.css'],
})
export class FilterPanelComponent implements OnInit {
  @Input() filter: IFilter | undefined;
  @Output() updateSelectedOptions = new EventEmitter<{ filter: IFilter, options: string[] }>();
  showFullList: boolean = false;
  searchControl = new FormControl();

  filterSubject: BehaviorSubject<IFilter|undefined> | undefined;
  filter$: Observable<IFilter|undefined> | undefined;
  filteredOptions$: Observable<IFilterOption[]|undefined> | undefined;

  protected readonly EFilterType = EFilterType;

  constructor() {}

  // Selects/Unselects an option
  select(value: string, count: number | undefined) {
    if(!this.filter?.selectedOptions ) { return; }
    let index = this.filter.selectedOptions.indexOf(value);
    (index > -1) ? this.filter.selectedOptions.splice(index,1) : this.filter.selectedOptions.push(value);
    this.updateSelectedOptions.emit({ filter: this.filter, options: this.filter.selectedOptions });
  }

  isSelected(value: string) {
    return this.filter?.selectedOptions?.includes(value) || false;
  }

  ngOnInit() {
    this.filterSubject = new BehaviorSubject<IFilter | undefined>(this.filter);
    this.filter$ = this.filterSubject.asObservable();

    this.filteredOptions$ = combineLatest([
      this.filter$ ?? of(undefined),
      this.searchControl.valueChanges.pipe(
          startWith(''),
          debounceTime(0),
          distinctUntilChanged()
      )
    ]).pipe(
        map(([filter, searchTerm]: [IFilter | undefined, string]) => {
          if (!filter) {
            return undefined;
          }
          if (searchTerm) {
            return filter?.options?.filter(option =>
                option.value.toLowerCase().includes(searchTerm)
            );
          }
          return filter.options;
        })
    );

  }

}
