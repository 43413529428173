<div class="column center-left flex credits-container">
  <div id="credits" class="banner-button row row-center-left" (click)="openCreditDropdown($event)">
    <div id="credits-icon" class="banner-button-icon">
      <img src="assets/images/credits.svg" />
    </div>
    <div class="banner-button-content column column-center-left">
      <div id="credits-content" class="column">
        <div id="credits-label" class="">Credits</div>
        <ng-container *ngIf="this.creditsInitialized$ | async; else notInitialised">
          <div id="credits-value" *ngIf="availableCredits !== null && totalCredits !== null" class="">
            {{ availableCredits }} / {{ totalCredits }}
          </div>
        </ng-container>
        <ng-template #notInitialised>
          <mat-spinner class="center" diameter="15"></mat-spinner>
        </ng-template>
      </div>
    </div>
  </div>

  <div [style.display]="isCreditDropdownOpen ? 'flex' : 'none'" class="credit-menu">
    <div class="column my-plan">
      <div class="plan-container">
        <div class="plan">Plan</div>
        <div class="plan-type" *ngIf="selectedPlans$ | async as plan">{{ plan.length > 0 ? plan[0].name : 'Free' }}</div>
      </div>
      <div class="available-credits">{{ availableCredits }} Credits</div>
      <div class="center credits-note">Credits reset every year.</div>
      <a class="button credit-button" [routerLink]="'settings/subscription'">Upgrade Plan</a>
    </div>
    <div class="credit-info column">
      <div>
        <div>CV Parsing & Insights</div>
        <div>1 Credit</div>
      </div>
      <div>
        <div>Job Matching</div>
        <div>1 Credit</div>
      </div>
      <div>
        <div>Job Parsing</div>
        <div>1 Credit</div>
      </div>
    </div>
  </div>
</div>
