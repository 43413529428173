import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {IApplicant} from "../../../../model/IApplicant";

@Injectable({
  providedIn: 'root'
})
export class ApplicantsService {

  applicants$: Observable<IApplicant[]|undefined> | undefined;
  selectedApplicants: string[] = [];
  multipleSelectMode: boolean = false;

  constructor() { }

  toggleMultipleSelectMode(): void {
    this.multipleSelectMode = !this.multipleSelectMode;
    this.selectedApplicants = [];
  }

  isMultipleSelectModeEnabled(): boolean {
    return this.multipleSelectMode;
  }

  selectApplicant(id: string) {
    if(this.isApplicantSelected(id)) {
      return;
    }
    this.selectedApplicants.push(id);
  }

  unselectApplicant(id: string) {
    const index = this.selectedApplicants.indexOf(id);
    if(index>=0) {
      this.selectedApplicants.splice(index, 1);
    }
  }

  isApplicantSelected(id: string) {
    if(!this.multipleSelectMode) {
      return false;
    }
    return this.selectedApplicants.includes(id);
  }
}
