<ng-container *ngIf="resume$ | async as resume; else empty">
    <div class="page-column fill-space resume column overflow-hidden">
        <div class="header column">
          <div class="title space-bottom-1">CV Analysis</div>
          <div class="header-buttons row-center-left">
              <div class="resume-menu">
                  <div class="resume-menu-item" [class]="{ 'selected': view === EResumeView.AI_VIEW }" (click)="view = EResumeView.AI_VIEW"> AI Parsed CV </div>
                  <div class="resume-menu-item" [class]="{ 'selected': view === EResumeView.ORIGINAL_VIEW }" (click)="view = EResumeView.ORIGINAL_VIEW"> Original CV File </div>
              </div>
              <div class="fill-space"></div>
            <div class="header-button" (click)="showEmailComposer(resume)">
              <div class="header-button-icon center">
                <img src="assets/images/email-icon.svg"/>
              </div>
              <div class="header-button-label center">Email</div>
            </div>
              <div class="header-button"  *ngIf="resumeFileUrl" (click)="copyFileUrl(resumeFileUrl)">
                  <div class="header-button-icon center">
                      <img *ngIf="!isUrlCopied" src="assets/images/url.svg"/>
                      <img *ngIf="isUrlCopied" src="assets/images/tick-green.svg"/>
                  </div>
                  <div class="header-button-label center" *ngIf="!isUrlCopied">Copy URL</div>
                  <div class="header-button-label center" *ngIf="isUrlCopied">URL Copied!</div>
              </div>
              <div class="header-button" *ngIf="resumeFileUrl" (click)="openFile(resumeFileUrl)">
                  <div class="header-button-icon center">
                      <img src="assets/images/open.svg"/>
                  </div>
                  <div class="header-button-label center">Open</div>
              </div>
              <div class="header-button" (click)="showDeleteResume(resume)">
                  <div class="header-button-icon center">
                      <img src="assets/images/delete.svg"/>
                  </div>
                  <div class="header-button-label center">Delete</div>
              </div>
            </div>

        </div>

        <div class="fill-space" *ngIf="view === EResumeView.ORIGINAL_VIEW">
          <iframe *ngIf="safeResumeFileUrl" [src]="safeResumeFileUrl" class="fill-space">  </iframe>
        </div>

        <div class="column fill-space overflow-hidden" *ngIf="view === EResumeView.AI_VIEW">
            <div class="resume-content column scrollbar">
              <resume-info
                  *ngIf="view === EResumeView.AI_VIEW"
                  [info]="resume.resumeAnalysis?.info"
                  [image]="resume.image">
              </resume-info>
                <ng-container *ngIf="resume.resumeAnalysis">
                    <resume-overview
                            *ngIf="resume.resumeAnalysis.overview"
                            [overview]="resume.resumeAnalysis.overview">
                    </resume-overview>
                    <div>
                        <resume-insights
                                *ngIf="resume.resumeAnalysis.overview.indicators"
                                [indicators]="resume.resumeAnalysis.overview.indicators">
                        </resume-insights>
                    </div>
                    <div class="row">
                        <resume-content [content]="resume.resumeAnalysis.content"></resume-content>
                    </div>
                </ng-container>
            </div>
        </div>

    </div>
</ng-container>

<ng-template #empty>
    <div class="empty fill-space column center">
        <div class="empty-icon"><img src="assets/images/empty.svg"/></div>
        <div class="empty-title"> No CV Selected</div>
        <div class="empty-text"> Please select a CV from the list</div>
    </div>
</ng-template>