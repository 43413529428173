import { Component, NgZone, OnInit } from '@angular/core';
import { IPaidSubscription, PaidSubscriptionsService } from '../../../services/paid-subscriptions.service';
import { PaymentsService } from '../../../services/payments.service';
import { BehaviorSubject, from, map, Observable, switchMap, take, tap } from 'rxjs';
import { Product } from '../../../../firestore-stripe-web-sdk/src';
import { Tier } from '../../../../firestore-stripe-web-sdk/src/product';
import { environment } from '../../../../environments/environment';

enum loadingState {
  NotSet = 'NotSet',
  Updating = 'Updating',
  WaitingForUpdate = 'WaitingForUpdate',
  Ready = 'Ready'
}

@Component({
  selector: 'settings-subscription',
  templateUrl: './settings-subscription.component.html',
  styleUrls: ['./settings-subscription.component.css']
})
export class SettingsSubscriptionComponent implements OnInit {
  protected selectedPlans$: Observable<IPaidSubscription[]> | undefined;
  protected plans$: Observable<Product[]> | undefined;
  protected subscriptionLoading: BehaviorSubject<loadingState> = new BehaviorSubject<loadingState>(loadingState.NotSet);
  protected readonly loadingState = loadingState;
  email: string = environment.hirefoxEmail;

  private unsubscribeFromSubscriptionUpdates: (() => void) | undefined;
  showManageLoader: boolean = false;
  constructor(
    private paidSubscriptionsService: PaidSubscriptionsService,
    private paymentsService: PaymentsService,
    private ngZone: NgZone
  ) {
    this.selectedPlans$ = this.paidSubscriptionsService.selectedPlans$;
  }

  ngOnInit() {
    this.selectedPlans$?.pipe(
      tap((selectedPlans) => {
        console.log('SubscriptionPlanComponent', 'selectedPlans', selectedPlans);
      })
    );

    void this.paymentsService
      .onSubscriptionUpdate(() => {
        this.ngZone.run(() => {
          this.paidSubscriptionsService.refreshSubscriptions$.next();
          console.log('SubscriptionPlanComponent', 'Subscription Updated');
        });
        this.subscriptionLoading.next(loadingState.Ready);
      })
      .then((unsubscribe) => (this.unsubscribeFromSubscriptionUpdates = unsubscribe));

    this.plans$ = from(this.paymentsService.getProducts())?.pipe(
      map((plans) => {
        return plans.flatMap((plan) => {
          if (plan.id === environment.stripe.allInOneProductId) {
            const { tiers } = plan.prices[0];

            // Split tiers into two arrays based on the `up_to` condition
            const tiersUpTo1500 = tiers.filter((tier) => tier.up_to !== null && tier.up_to <= 1500);
            const tiersAbove1500 = tiers.filter((tier) => tier.up_to !== null && tier.up_to > 1500);

            // Create two separate objects
            const starter = {
              ...plan,
              name: 'Start',
              prices: [
                {
                  ...plan.prices[0],
                  tiers: tiersUpTo1500
                }
              ]
            };

            const scale = {
              ...plan,
              name: 'Scale',
              prices: [
                {
                  ...plan.prices[0],
                  tiers: tiersAbove1500
                }
              ]
            };

            return [starter, scale];
          }
          return [];
        });
      })
    );
  }

  subscribeToPlan(event: { plan: Product; selectedTier: Tier }) {
    this.selectedPlans$
      ?.pipe(
        take(1),
        switchMap((selectedPlans) => {
          const userHasActivePlan = selectedPlans.length > 0;

          this.subscriptionLoading.next(userHasActivePlan ? loadingState.WaitingForUpdate : loadingState.Updating);

          return userHasActivePlan
            ? this.paidSubscriptionsService.updateSubscriptionSession(event.plan.prices[0].id, event.selectedTier.up_to!)
            : this.paidSubscriptionsService.startCheckoutSession(event.plan.prices[0].id, event.selectedTier.up_to!);
        })
      )
      .subscribe({
        error: () => {
          this.subscriptionLoading.next(loadingState.Ready);
        }
      });
  }

  toggleManageLoader($event: boolean) {
    this.showManageLoader = $event;
  }

  hasActiveSubscription(selectedPlan: IPaidSubscription[]) {
    if (selectedPlan.length === 0) return false;
    return true;
    // return selectedPlan.find(plan => {
    //   plan.
    // })
    // selectedPlan[0].cancelAtPeriodEnd
  }
}
