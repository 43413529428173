import { Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  doc,
  docData,
  Firestore,
  getDoc,
  orderBy,
  query,
  runTransaction,
  updateDoc
} from '@angular/fire/firestore';
import { IUser, IUserInOrganization, IUserInOrganizationFirebase } from '../../../../model/IUser';
import { FirebaseCollectionNames } from '../../../../model/FirebaseCollectionNames';
import { FirebaseConverterService } from './firebase-converter.service';
import { IOrganization, IPortalConfiguration } from '../../../../model/IOrganization';
import { propertyOf } from '../../../../model/utils';
import { Api } from './api';
import { EInvitationStatus } from '../../../../model/enums/EInvitationStatus';
import { EUserRole } from '../../../../model/enums/EUserRole';
import { DeepPartial } from 'chart.js/dist/types/utils';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsApi extends Api<IOrganization> {
  constructor(
    private firestore: Firestore,
    private organizationConverterService: FirebaseConverterService<IOrganization>,
    private userConverterService: FirebaseConverterService<IUserInOrganizationFirebase>
  ) {
    super();
  }

  create(ownerUserId: string, companyName: string) {
    return runTransaction(this.firestore, async (transaction) => {
      // Get the owner user
      const ownerUserDoc = await transaction.get(doc(collection(this.firestore, FirebaseCollectionNames.USERS), ownerUserId));
      const ownerUser = ownerUserDoc.data()! as IUser;

      // Create the organization
      const newOrganizationDocumentRef = doc(collection(this.firestore, FirebaseCollectionNames.ORGANIZATIONS));

      transaction.set(newOrganizationDocumentRef, {
        name: companyName
      } as IOrganization);

      // Add the owner to the organization
      const usersInNewOrganizationCollectionRef = doc(collection(newOrganizationDocumentRef, FirebaseCollectionNames.USERS), ownerUserId);
      const userToBeAddedToNewOrganization = {
        name: ownerUser.name,
        email: ownerUser.email,
        emailVerified: ownerUser.emailVerified,
        status: EInvitationStatus.ACCEPTED,
        role: EUserRole.OWNER
      } satisfies Omit<IUserInOrganization, 'id'>;

      transaction.set(usersInNewOrganizationCollectionRef, userToBeAddedToNewOrganization);

      // Update the user with the new organization
      const userOrganizations = ownerUser.organizations ?? [];
      userOrganizations.push(newOrganizationDocumentRef.id);

      transaction.update(ownerUserDoc.ref, {
        organizations: userOrganizations
      } as Pick<IUser, 'organizations'>);
    });
  }

  getOne(organizationId: string) {
    return docData(
      doc(
        collection(this.firestore, FirebaseCollectionNames.ORGANIZATIONS).withConverter(this.organizationConverterService.converter),
        organizationId
      )
    );
  }

  getOneOnce(organizationId: string) {
    const documentReference = getDoc(
      doc(
        collection(this.firestore, FirebaseCollectionNames.ORGANIZATIONS).withConverter(this.organizationConverterService.converter),
        organizationId
      )
    );

    return getDoc(
      doc(
        collection(this.firestore, FirebaseCollectionNames.ORGANIZATIONS).withConverter(this.organizationConverterService.converter),
        organizationId
      )
    );
  }

  getOneUser(organizationId: string, userId: string) {
    return docData(
      doc(
        collection(this.firestore, FirebaseCollectionNames.ORGANIZATIONS, organizationId, FirebaseCollectionNames.USERS),
        userId
      ).withConverter(this.userConverterService.converter)
    );
  }

  getAll(organizationId: string) {
    return collectionData(
      query(
        collection(this.firestore, FirebaseCollectionNames.ORGANIZATIONS, organizationId, FirebaseCollectionNames.USERS).withConverter(
          this.userConverterService.converter
        ),
        orderBy(propertyOf<IUserInOrganizationFirebase>('role'))
      )
    );
  }

  updateName(organizationId: string, name: string) {
    return updateDoc(doc(this.firestore, FirebaseCollectionNames.ORGANIZATIONS, organizationId), {
      name
    } as Pick<IOrganization, 'name'>);
  }

  togglePortal(organizationId: string, isPortalEnabled: boolean) {
    return updateDoc(doc(this.firestore, FirebaseCollectionNames.ORGANIZATIONS, organizationId), {
      isPortalEnabled
    } as Pick<IOrganization, 'isPortalEnabled'>);
  }

  // updatePortalConfiguration(organizationId: string, config: DeepPartial<PortalConfiguration>) {
  //   return updateDoc(doc(this.firestore, FirebaseCollectionNames.ORGANIZATIONS, organizationId), {
  //     ...config
  //   });
  // }

  updatePortalConfiguration(organizationId: string, config: DeepPartial<IPortalConfiguration>) {
    return updateDoc(doc(this.firestore, FirebaseCollectionNames.ORGANIZATIONS, organizationId), {
      portalConfiguration: config
    });
  }
}
