import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, map, Observable, of, Subscription, switchMap } from 'rxjs';
import { ICredits } from '../../../../../../../model/ICredits';
import { IOrganization } from '../../../../../../../model/IOrganization';
import { CreditsApi } from '../../../../api/credits.api';
import { OrganizationsApi } from '../../../../api/organizations.api';
import { ActivatedRoute } from '@angular/router';
import { propertyOf } from '../../../../../../../model/utils';

@Component({
  selector: 'my-credits',
  templateUrl: './my-credits.component.html',
  styleUrls: ['./my-credits.component.css']
})
export class MyCreditsComponent implements OnInit, OnDestroy {
  availableCredits: number | null = 0;
  totalCredits: number | null = 0;
  creditsPackages$: Observable<ICredits[]> | undefined;
  creditsInitialized$: Observable<boolean> | undefined;
  organization$: Observable<IOrganization | null | undefined> | undefined;
  private creditsSubscription: Subscription | undefined;
  creditsPercentage: number | null = null;
  activeCreditsPackages: ICredits[] = [];

  constructor(
    private creditsApi: CreditsApi,
    private organizationApi: OrganizationsApi,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.creditsPackages$ = this.creditsApi.getAll([
      { field: propertyOf<ICredits>('available'), condition: '>', value: 0 },
      { field: propertyOf<ICredits>('startDate'), condition: '<=', value: new Date().getTime() / 1000 },
      { field: propertyOf<ICredits>('endDate'), condition: '>=', value: new Date().getTime() / 1000 }
    ]);

    this.creditsSubscription = this.creditsPackages$.subscribe((creditsPackages) => {
      this.calculateAvailableCredits(creditsPackages);
      this.calculateAvailableCreditsPercentage();
      this.activeCreditsPackages = creditsPackages;
    });

    this.organization$ = this.route.params.pipe(
      switchMap((params) => {
        const organizationId = params['organizationId'];
        return organizationId ? this.organizationApi.getOne(organizationId) : of(undefined);
      })
    );

    this.creditsInitialized$ = this.organization$?.pipe(
      filter((organization) => organization !== null && organization !== undefined),
      map((organization) => !!organization!.customerId)
    );
  }

  calculateAvailableCredits(creditsPackages: ICredits[]) {
    const reducedCredits = creditsPackages.reduce(
      (acc, creditPackage) => {
        acc.available += creditPackage.available;
        acc.total += creditPackage.total;
        return acc;
      },
      { available: 0, total: 0 }
    );

    this.availableCredits = reducedCredits.available;
    this.totalCredits = reducedCredits.total;
  }

  ngOnDestroy() {
    this.creditsSubscription?.unsubscribe();
  }

  private calculateAvailableCreditsPercentage() {
    this.creditsPercentage = this.availableCredits ? (this.availableCredits / this.totalCredits!) * 100 : 0;
  }
}
