import {ChangeDetectorRef, Component, Input} from "@angular/core";
import {IApplicantGroup, ICategoryDefinition} from "../../../../../../model/IApplicantGroup";
import {UtilService} from "../../../../../../services/util.service";
import {IApplicant} from "../../../../../../../../../model/IApplicant";
import {EImageStatus, EStatus} from "../../../../../../../../../model/enums/EResumeStatuses";
import {FirebaseStorageFolderNames} from "../../../../../../../../../model/FirebaseCollectionNames";
import {StoreService} from "../../../../../../services/store.service";
import {StorageApi} from "../../../../../../api/storage.api";
import {IResumeImage} from "../../../../../../../../../model/IResume";
import {ApplicantsService} from "../../../../../../services/applicants.service";

@Component({
  selector: "job-applicant-panel",
  templateUrl: "./job-applicant-panel.component.html",
  styleUrls: ["./job-applicant-panel.component.css"],
})
export class JobApplicantPanelComponent {
  @Input() applicant: IApplicant | undefined;
  @Input() group: IApplicantGroup | undefined;
  @Input() selectedApplicantId: string | undefined;


  categoryDefinition: ICategoryDefinition | undefined;


  constructor(protected utilService: UtilService, public applicantsService: ApplicantsService, private cdr: ChangeDetectorRef) {

  }

  isApplicantSelected(id: string): boolean {
    return this.applicantsService.isApplicantSelected(id);
  }

  toggleApplicantSelection(id: string) {
    if(!this.applicantsService.multipleSelectMode) {
      return;
    }
    if(this.applicantsService.isApplicantSelected(id)) {
      this.applicantsService.unselectApplicant(id);
      return;
    }
    this.applicantsService.selectApplicant(id);
    this.cdr.detectChanges();
  }


  // Returns the class to apply when an applicant is selected based on their group (e.g., perfect fit gets a green border, great fit a blue etc.)
  getSelectedClass(id: string) {
    if(this.isApplicantSelected(id)) {
      return "multi-select-border";
    }
    if (this.selectedApplicantId && this.selectedApplicantId === this.applicant?.id) {
      return this.categoryDefinition?.styleClass + "-border";
    }
    return undefined;
  }


  ngOnInit() {

    this.categoryDefinition = this.utilService.getCategory(this.group?.type!);
  }

  protected readonly EStatus = EStatus;
}
