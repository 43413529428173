import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { filter, interval, map, Observable, switchMap, take } from "rxjs";
import {IResume, IResumeFile} from "../../../../../../model/IResume";
import { ResumesApi } from "../../../api/resumes.api";
import { StorageApi } from "../../../api/storage.api";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ResumeDeleteComponent } from "../resume-delete/resume-delete.component";
import { StoreService } from "../../../services/store.service";
import { FirebaseStorageFolderNames } from "../../../../../../model/FirebaseCollectionNames";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import {EFileType} from "../../../../../../model/enums/EFileType";
import {EmailComposerComponent} from "../../../common/email-composer/email-composer.component";
import {UserService} from "../../../services/user.service";
import {IEmailContact} from "../../../../../../model/IEmail";

enum EResumeView {
  AI_VIEW = "ai view",
  ORIGINAL_VIEW = "original view",
}

@Component({
  selector: "resume",
  templateUrl: "./resume.component.html",
  styleUrls: ["./resume.component.css"],
})
export class ResumeComponent implements OnInit, OnDestroy {
  resume$: Observable<IResume | undefined> | undefined;
  isUrlCopied: boolean = false;
  resumeFileUrl: string | undefined;
  safeResumeFileUrl: SafeResourceUrl | undefined;
  view: EResumeView = EResumeView.AI_VIEW;

  constructor(
    private route: ActivatedRoute,
    private resumesApi: ResumesApi,
    private storageApi: StorageApi,
    private dialog: MatDialog,
    private store: StoreService,
    private sanitizer: DomSanitizer,
    private userService: UserService
  ) {}

  showEmailComposer(resume: IResume) {
    if(!resume?.resumeAnalysis?.info?.contactInfo?.emails?.length) {
      return;
    }
    let recipient: IEmailContact = {
      name: resume.resumeAnalysis.info.name ,
      email: resume.resumeAnalysis.info.contactInfo.emails[0]
    }
    let dialogRef: MatDialogRef<EmailComposerComponent> | undefined;
    dialogRef = this.dialog.open(EmailComposerComponent, {});
    dialogRef.componentInstance.recipients = [ recipient ];
  }

  showDeleteResume(resume: IResume) {
    let dialogRef: MatDialogRef<ResumeDeleteComponent> | undefined;
    dialogRef = this.dialog.open(ResumeDeleteComponent, {});
    dialogRef.componentInstance.resume = resume;
  }

  async copyToClipboard(url: string): Promise<void> {
    try {
      await navigator.clipboard.writeText(url);
    } catch (err) {
      console.error("Failed to copy URL to clipboard:", err);
    }
  }

  async copyFileUrl(fileUrl: string) {
    if (!fileUrl) {
      return;
    }
    this.isUrlCopied = true;
    await this.copyToClipboard(fileUrl);
    interval(2000).pipe(take(1)).subscribe(() => { this.isUrlCopied = false; });
  }

  openFile(fileUrl: string) {
    if (!fileUrl) {
      return;
    }
    window.open(fileUrl, "_blank");
  }

  getResumeFileUrl(file: IResumeFile) {
    if (!file.path) {
      return;
    }
    this.storageApi
      .getFileUrl(file.path)
      .then((fileUrl) => {
        this.resumeFileUrl = fileUrl;
        if (file.type === EFileType.PDF) {
          this.safeResumeFileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl + "#pagemode=none&zoom=100");
        } else
        if(file.type === EFileType.DOCX) {
          this.safeResumeFileUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://drive.google.com/viewerng/viewer?embedded=true&url=' + encodeURIComponent(fileUrl));
        } else {
          this.safeResumeFileUrl = undefined;
        }
      })
      .catch((error) => {
        console.error("Error Getting File Url: " + error);
      })
      .finally(() => {});
  }

  ngOnInit() {
    this.resume$ = this.route.paramMap.pipe(
      map((params) => ({
        orgId: this.store.organizationId!,
        resumeId: params.get("resumeId") as string,
      })),
      filter(
        (resumeAngOrgIds) =>
          !!(
            resumeAngOrgIds &&
            resumeAngOrgIds.resumeId &&
            resumeAngOrgIds.orgId
          ),
      ),
      switchMap((resumeAngOrgIds) =>
        this.resumesApi.getOne(resumeAngOrgIds.resumeId),
      ),
    );
    this.resume$.subscribe((resume) => {
      if (resume) {
        this.resumeFileUrl = undefined;
        this.safeResumeFileUrl = undefined;
        this.getResumeFileUrl(resume.file);
      }
    });
  }

  ngOnDestroy() {}

  protected readonly EResumeView = EResumeView;
  protected readonly EFileType = EFileType;
}
