import { Injectable } from '@angular/core';
import * as localforage from "localforage";

enum EApplicantPage {
  "EVALUATION" = "ai-evaluation",
  "RESUME" = "ai-resume",
  "NOTES" = "notes",
  "RATINGS" = "ratings"
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  activeApplicantSection: string = EApplicantPage.EVALUATION;

  constructor() { }

  getActiveApplicantSection(): string {
    if(localStorage.activeApplicantSection) {
      this.activeApplicantSection = localStorage.activeApplicantSection;
    }
    return this.activeApplicantSection;
  }

  setActiveApplicantSection(newActiveApplicantSection: string): void {
    this.activeApplicantSection = newActiveApplicantSection;
    localStorage.activeApplicantSection = this.activeApplicantSection;
  }
}
