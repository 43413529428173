<div id="subscription-settings" class="gap-3" *ngIf="selectedPlans$ | async as selectedPlan">
  <div id="subscription-settings-content" class="column gap-1">
    <div class="gap-1">
      <div class="subscription-title">My Plan</div>
      <mat-spinner *ngIf="subscriptionLoading.value !== loadingState.Ready" diameter="20"> ></mat-spinner>
      <mat-spinner *ngIf="showManageLoader" diameter="20"> ></mat-spinner>
    </div>
    <my-subscription [currentPlan]="selectedPlan" (showManageLoader)="toggleManageLoader($event)"></my-subscription>
    <div class="column gap-3 custom-plan">
      <div class="column-top-center gap-2">
        <div>
          <img src="assets/images/custom.svg" alt="custom plan" />
        </div>
        <div class="inline-block">
          Do you need a&nbsp;<strong>custom plan ?</strong>&nbsp;Contact us to get a quote tailored to your needs.
        </div>
      </div>
      <a class="flex button custom-plan-button" href="mailto:{{ email }}">Contact Us</a>
    </div>
    <my-credits></my-credits>
  </div>
  <div
    class="column"
    id="upgraded-plans"
    *ngIf="
      selectedPlan.length === 0 ||
      (selectedPlan.length && selectedPlan[0].cancelAtPeriodEnd)
    ">
    <div class="plan-title">Upgrade Plan</div>
    <div class="column gap-2">
      <subscription-plan-card
        [currentPlan]="selectedPlan"
        *ngFor="let plan of plans$ | async"
        [plan]="plan"
        (selectPlan)="subscribeToPlan($event)">
      </subscription-plan-card>
    </div>
  </div>
</div>
