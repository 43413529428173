import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { CreditsApi } from '../../../api/credits.api';
import { filter, map, Observable, of, Subscription, switchMap, tap } from 'rxjs';
import { ICredits } from '../../../../../../model/ICredits';
import { OrganizationsApi } from '../../../api/organizations.api';
import { propertyOf } from '../../../../../../model/utils';
import { IOrganization } from '../../../../../../model/IOrganization';
import { ActivatedRoute } from '@angular/router';
import { IPaidSubscription, PaidSubscriptionsService } from '../../../services/paid-subscriptions.service';

@Component({
  selector: 'credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.css']
})
export class CreditsComponent implements OnInit, OnDestroy {
  availableCredits: number | null = null;
  totalCredits: number | null = null;
  creditsPackages$: Observable<ICredits[]> | undefined;
  creditsInitialized$: Observable<boolean> | undefined;
  organization$: Observable<IOrganization | null | undefined> | undefined;
  isCreditDropdownOpen: boolean = false;
  private creditsSubscription: Subscription | undefined;
  protected selectedPlans$: Observable<IPaidSubscription[]> | undefined;

  constructor(
    private creditsApi: CreditsApi,
    private organizationApi: OrganizationsApi,
    private route: ActivatedRoute,
    private paidSubscriptionsService: PaidSubscriptionsService,
    private elementRef: ElementRef
  ) {}

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
      this.isCreditDropdownOpen = false; // Close the dropdown if we click outside the component
  }

  calculateAvailableCredits(creditsPackages: ICredits[]) {
    const reducedCredits = creditsPackages.reduce(
      (acc, creditPackage) => {
        acc.available += creditPackage.available;
        acc.total += creditPackage.total;
        return acc;
      },
      { available: 0, total: 0 }
    );

    this.availableCredits = reducedCredits.available;
    this.totalCredits = reducedCredits.total;
  }

  ngOnInit() {
    this.creditsPackages$ = this.creditsApi.getAll([
      { field: propertyOf<ICredits>('available'), condition: '>', value: 0 },
      { field: propertyOf<ICredits>('startDate'), condition: '<=', value: new Date().getTime() / 1000 },
      { field: propertyOf<ICredits>('endDate'), condition: '>=', value: new Date().getTime() / 1000 }
    ]);

    this.creditsSubscription = this.creditsPackages$.subscribe((creditsPackages) => {
      this.calculateAvailableCredits(creditsPackages);
    });

    this.selectedPlans$ = this.paidSubscriptionsService.selectedPlans$;

    this.organization$ = this.route.params.pipe(
      switchMap((params) => {
        const organizationId = params['organizationId'];
        return organizationId ? this.organizationApi.getOne(organizationId) : of(undefined);
      })
    );

    this.creditsInitialized$ = this.organization$?.pipe(
      filter((organization) => organization !== null && organization !== undefined),
      map((organization) => !!organization!.customerId)
    );
  }

  ngOnDestroy() {
    this.creditsSubscription?.unsubscribe();
  }

  openCreditDropdown(event: Event) {
    event.stopPropagation(); // Prevent triggering the click listener
    this.isCreditDropdownOpen = !this.isCreditDropdownOpen;
  }
}
