// https://angular.dev/tools/cli/environments#

export const environment = {
  typesense: {
    api: 'https://f7ojwqcx3m8bk2plp-1.a1.typesense.net:443',
    searchKey: 'vz9sXYy08v9HgRRGGkNUt2zKFBHVXTIf',
    collections: {
      resumes: 'resumes-production',
      jobs: 'jobs-production'
    }
  },
  appUrl: 'https://app.hirefox.ai',
  register: 'https://app.hirefox.ai/register',
  emailVerification: 'https://app.hirefox.ai/email-verification',
  passwordReset: 'https://app.hirefox.ai/reset-password',
  firebase: {
    projectId: 'hirefox-prod',
    appId: '1:353360352833:web:a092277c179d2fc8cf18c0',
    storageBucket: 'hirefox-prod.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyDTLQUNXjm6vvwkXEMh0VVQfM8THPrOubU',
    authDomain: 'hirefox-prod.firebaseapp.com',
    messagingSenderId: '353360352833'
  },
  localEmulators: false,
  sentry: {
    environment: 'production',
    enabled: true, // enable or disable sentry
    dsn: 'https://39e3d95f13543bb62cc510aad95aaddf@o1180461.ingest.us.sentry.io/4507056522330112', // do not change this
    tracesSampleRate: 1.0, // Set tracesSampleRate to 0.05 to capture 5% of transactions
    tracePropagationTargets: [
      // "localhost",
      /^https:\/\/app\.hirefox\.ai/
    ], // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    replaysSessionSampleRate: 1.0, // Capture Replay for 100% of all sessions
    replaysOnErrorSampleRate: 1.0 // Capture Replay for 100% of sessions with an error
  },
  stripe: {
    allInOneProductId: 'prod_RPTZF40jhjrPjf',
    creditsProductPriceId: 'price_1QWecIDkPgL45Xb7JEFCE8nG'
  },
  hirefoxEmail: 'info@hirefox.ai'
};
