<div class="job-applicant-category column" [class]="{'gray-out': !applicantsCount}" *ngIf="group && categoryDefinition">
  <div class="category-header row">
    <div class="category-header-icon"> <img [src]="'assets/images/' + categoryDefinition.icon" /> </div>
    <div class="category-header-label">{{ categoryDefinition.label }}</div>
    <div class="category-header-dot center"> <img src="../../../../../../../assets/images/dot-gray.svg" /> </div>
    <div class="category-header-percentage row-center-left fill-space"> Score > {{ categoryDefinition.percentageAbove }} </div>
    <div class="category-header-total"> {{ applicantsCount != 1 ? applicantsCount+' Applicants': applicantsCount+' Applicant' }}  </div>
  </div>
  <div class="applicant-list column" *ngIf="applicants$ | async as applicants">

      <job-applicant-panel
              *ngFor="let a of applicants | filterApplicantsByGroup: group | orderBy: 'matchAnalysis.overallScore' : 'desc'"
              [routerLink]="!applicantsService.isMultipleSelectModeEnabled() ? [a.id] : null"
              [applicant]="a"
              [selectedApplicantId]="selectedApplicantId"
              [group]="group">
      </job-applicant-panel>

  </div>
</div>
