import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IEmail, IEmailContact} from "../../../../../model/IEmail";
import {EmailService} from "../../services/email.service";
import {UserService} from "../../services/user.service";
import {Observable, of, Subscription} from "rxjs";
import {EmailsApi} from "../../api/emails.api";

enum EmailDeliveryStatus {
  "NORMAL" = "NORMAL",      // User is writing the email
  "WAITING" = "WAITING",    // Email is sent. Waiting for delivery confirmation
  "SUCCESS" =  "SUCCESS",   // Email delivered.
  "ERROR" = "ERROR"         // Email was not delivered.
}

@Component({
  selector: 'email-composer',
  templateUrl: './email-composer.component.html',
  styleUrls: ['./email-composer.component.css']
})
export class EmailComposerComponent implements OnInit, OnDestroy {

  @Input() recipients: IEmailContact[] | null | undefined;
  subject: string = "";
  message: string = "";
  error: string = "";
  emails: Observable<IEmail|undefined>[] = [];
  subscription: Subscription | undefined;
  isLoading: boolean = false;
  isSent: boolean = false;

  constructor(private emailService: EmailService, private emailsApi: EmailsApi, private userService: UserService) {

  }

  send(): void {
    if(!this.recipients || !this.recipients.length || !this.userService.user?.name || !this.userService.user?.email) {
      return;
    }
    this.isSent = true;
    for (let i=0;i< this.recipients.length; i++) {
      let email: IEmail = this.emailService.createPersonalMessageEmail([this.recipients[i]], this.subject, this.message, this.userService.user?.name, this.userService.user?.email);
      this.emailService.sendEmail(email).then( (result) => {
        if(result?.id) {
          let email$ = this.emailsApi.getOne(result.id);
          this.emails.push(email$);
        }
      }).catch( (error)=> {
        this.emails.push(of(undefined));
        console.log(error);
      }).finally( ()=> {

      })
    }

  }



  ngOnInit() {

  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe(); // Clean up the subscription
    }
  }


  protected readonly EmailDeliveryStatus = EmailDeliveryStatus;
}
