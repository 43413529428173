import { Injectable } from "@angular/core";
import {addDoc, collection, collectionData, deleteDoc, doc, docData, Firestore, orderBy, query, setDoc, where} from "@angular/fire/firestore";
import { FirebaseCollectionNames } from "../../../../model/FirebaseCollectionNames";
import { StoreService } from "../services/store.service";
import {IUser, IUserInOrganization} from "../../../../model/IUser";
import { Api } from "./api";
import {Observable} from "rxjs";
import {FirestoreFilter} from "../model/firestore.filter";
import {FirestoreSorting} from "../model/firestore.sorting";
import {QueryConstraint} from "@firebase/firestore";
import {IResume} from "../../../../model/IResume";
import {IInvitation} from "../../../../model/IInvitation";
import {IEmail} from "../../../../model/IEmail";
import {IJob} from "../../../../model/IJob";

@Injectable({
  providedIn: "root",
})
export class EmailsApi extends Api<IEmail> {
  constructor(private firestore: Firestore) {
    super();
  }

  create(email: IEmail) {
    let reference = collection(this.firestore, this.getPath());
    return addDoc(reference, email);
  }

  getOne(id: string) {
    const reference = doc(this.firestore, this.getPath(), id);
    return docData<IEmail>(reference as any, this.options);
  }

  private getPath() {
    return FirebaseCollectionNames.EMAILS;
  }
}
