import { Injectable } from '@angular/core';
import { FirebaseApp, getApp } from '@angular/fire/app';
import {
  CommonSessionCreateParams,
  createCheckoutSession,
  createUpdateSubscriptionSession,
  getCurrentUserPayments,
  getCurrentUserSubscription,
  getCurrentUserSubscriptions,
  getProducts,
  getStripePayments,
  LineItemParams,
  LineItemSessionCreateParams,
  onCurrentUserSubscriptionUpdate,
  PriceIdSessionCreateParams,
  Session,
  SessionCreateParams,
  StripePayments,
  SubscriptionSnapshot
} from '../../firestore-stripe-web-sdk/src';
import { StoreService } from './store.service';
import { Functions, httpsCallable } from '@angular/fire/functions';
import {
  PortalLinkForOrganizationRequest,
  PortalLinkForOrganizationResponse
} from '../../../../model/IPortalLinkForOrganization';

type SingleOrManyProducts =
  | Omit<LineItemSessionCreateParams, keyof CommonSessionCreateParams>
  | Omit<PriceIdSessionCreateParams, keyof CommonSessionCreateParams>;

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  payments: StripePayments;

  constructor(
    firebaseApp: FirebaseApp,
    private store: StoreService,
    private functions: Functions
  ) {
    this.payments = getStripePayments(firebaseApp, {
      productsCollection: 'products',
      customersCollection: 'customers',
      customerIdOverride: store.organizationId!
    });
  }

  async checkIfPaymentsInitialized() {
    if (!this.payments) {
      const errorMessage = 'Stripe Payments is not initialized.';
      console.error(errorMessage);
      return Promise.reject(errorMessage);
    }
  }

  async getProducts() {
    await this.checkIfPaymentsInitialized();

    return getProducts(this.payments, {
      includePrices: true,
      activeOnly: true
    });
  }

  async getOrganizationSubscription(subscriptionId: string) {
    await this.checkIfPaymentsInitialized();

    return getCurrentUserSubscription(this.payments, subscriptionId);
  }

  async getOrganizationSubscriptions() {
    await this.checkIfPaymentsInitialized();

    return getCurrentUserSubscriptions(this.payments);
  }

  async onSubscriptionUpdate(callback: () => void) {
    await this.checkIfPaymentsInitialized();

    return onCurrentUserSubscriptionUpdate(this.payments, callback);
  }

  async createPortalLinkForOrganization(organizationId: string) {
    await this.checkIfPaymentsInitialized();

    const portalFunctionRef = httpsCallable<PortalLinkForOrganizationRequest, PortalLinkForOrganizationResponse>(
      this.functions,
      'createPortalLink'
    );

    const { data } = await portalFunctionRef({
      customer_id: organizationId,
      return_url: window.location.toString()
    });
    return data;
  }

  async startCheckout(customerId: string, products: SingleOrManyProducts, organizationId?: string) {
    await this.checkIfPaymentsInitialized();

    const baseUrl = window.location;
    const successUrl = `${baseUrl}/../../checkout/success/{CHECKOUT_SESSION_ID}`;
    const cancelUrl = `${baseUrl}/../../checkout/cancel/{CHECKOUT_SESSION_ID}`;

    return createCheckoutSession(this.payments, {
      mode: 'subscription',
      ...products,
      metadata: {
        organizationId
      },
      automatic_tax: true,
      tax_id_collection: true,
      success_url: baseUrl.toString(),
      cancel_url: baseUrl.toString()
    });
  }

  async updateSubscription(
    customerId: string,
    subscriptionId: string,
    items: LineItemParams[],
    organizationId?: string
  ) {
    await this.checkIfPaymentsInitialized();

    return createUpdateSubscriptionSession(this.payments, {
      subscription_id: subscriptionId,
      items,
      automatic_tax: { enabled: true },
      metadata: {
        organizationId: organizationId!
      }
    });
  }
}
