<div id="settings" class="page-column column gap-2 fill-space overflow-hidden scrollbar">
  <div class="title">Settings</div>
  <div class="gap-3 fill-space overflow-hidden">
    <div class="column settings-sidenav">
      <div class="fill-space column gap-2">
        <settings-menu class="flex"></settings-menu>
      </div>
      <div>
        <div id="settings-menu-logout" class="row row-center-left" (click)="signOut()">
          <div id="settings-menu-logout-icon" class="space-right-1">
            <img src="assets/images/logout.svg" />
          </div>
          <div id="settings-menu-logout-text">Logout</div>
        </div>
      </div>
    </div>

    <div class="column fill-space overflow-hidden scrollbar">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
