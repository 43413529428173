<div class="subscription-plan column">
  <div class="gap-1 column">
    <div class="subscription-plan-info gap-1">
      <div class="subscription-plan-name">{{ planName }}</div>
      <div class="subscription-plan-status-container" *ngIf="status">
        <div
          class="subscription-plan-status-indicator"
          [ngClass]="status === 'active' ? 'subscription-plan-status-active' : 'subscription-plan-status-inactive'"></div>
        <div class="subscription-plan-status">
          {{ status | titlecase }}
        </div>
      </div>
    </div>

    <div class="subscription-plan-credits-header gap-1">
      <div class="subscription-plan-credits">
        {{ currentPlan.length ? currentPlan[0].credits : 0 }}&nbsp;<span class="subscription-plan-credits-year"> credits / Year</span>
      </div>
      <div class="subscription-plan-renewal-info" *ngIf="currentPlan.length > 0 && !currentPlan[0].cancelAtPeriodEnd">
        Auto renew:&nbsp;
        <div class='subscription-plan-renewal-info-date'>
          {{ currentPlan[0].periodEnd | date }}
        </div>
      </div>
    </div>

    <div class="button subscription-plan-button" (click)="goToPortal()">Manage Subscription</div>
  </div>
</div>
