import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent {
  constructor(private authService: AuthService) {}

  signOut(): void {
    this.authService.signOut().catch(() => {
      console.log('Logout Error');
    });
  }
}
