import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Observable, of, switchMap} from "rxjs";
import {ApplicantsApi} from "../../../../../../api/applicants.api";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {UserDeleteComponent} from "../../../../../users/user-delete/user-delete.component";
import {ApplicantDeleteComponent} from "./applicant-delete/applicant-delete.component";
import {IApplicant} from "../../../../../../../../../model/IApplicant";
import {IResume} from "../../../../../../../../../model/IResume";
import {EmailComposerComponent} from "../../../../../../common/email-composer/email-composer.component";
import {IEmailContact} from "../../../../../../../../../model/IEmail";
import {ConfigService} from "../../../../../../services/config.service";

@Component({
    selector: 'applicant', templateUrl: './applicant.component.html', styleUrls: ['./applicant.component.css']
})
export class ApplicantComponent implements OnInit {

    applicant$: Observable<IApplicant | undefined> | undefined;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private applicantsApi: ApplicantsApi,
        private configService: ConfigService
    ) {}

    showEmailComposer(applicant: IApplicant) {
        if (!applicant?.info?.contactInfo?.emails?.length || !applicant?.info?.name) {
            return;
        }
        let recipient: IEmailContact = {
            name: applicant.info.name ,
            email: applicant.info.contactInfo.emails[0]
        }
        let dialogRef: MatDialogRef<EmailComposerComponent> | undefined;
        dialogRef = this.dialog.open(EmailComposerComponent, {});
        dialogRef.componentInstance.recipients = [ recipient ];
    }

    showDeleteApplicant(applicant: IApplicant) {
        let dialogRef = this.dialog.open(ApplicantDeleteComponent, {});
        dialogRef.componentInstance.applicant = applicant;
    }

    setRoute(newRoute: string) {
        this.configService.setActiveApplicantSection(newRoute);
    }

    ngOnInit() {
        this.applicant$ = this.route.paramMap.pipe(switchMap((params) => {
            let applicantId = params.get("applicantId");
            return applicantId ? this.applicantsApi.getOne(applicantId) : of(undefined);
        }));
        let activeSection = this.configService.getActiveApplicantSection();
        this.router.navigate([activeSection], { relativeTo: this.route });

    }
}
