import {Component} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {StoreService} from "../../../services/store.service";
import {UserFormComponent} from "../user-form/user-form.component";
import {EUserRole} from "../../../../../../model/enums/EUserRole";
import {IInvitation} from "../../../../../../model/IInvitation";
import {IOrganization} from "../../../../../../model/IOrganization";
import {TransactionsApi} from "../../../api/transactions.api";
import {IOrganizationUser} from "../../../../../../model/IOrganizationUser";
import {EInvitationStatus} from "../../../../../../model/enums/EInvitationStatus";
import {InvitationsApi} from "../../../api/invitations.api";
import {UserService} from "../../../services/user.service";
import {EmailService} from "../../../services/email.service";
import {IEmail} from "../../../../../../model/IEmail";

@Component({
  selector: 'invitation-create',
  templateUrl: './invitation-create.component.html',
  styleUrls: ['./invitation-create.component.css']
})
export class InvitationCreateComponent {
  isLoading: boolean = false;
  email: string | undefined;
  role: EUserRole = EUserRole.MANAGER;

  constructor(
    private dialogRef: MatDialogRef<UserFormComponent>,
    private transactionsApi: TransactionsApi,
    public store: StoreService,
    private invitationsApi: InvitationsApi,
    private userService: UserService,
    private emailService: EmailService
  ) {}


  inviteUser(organization: IOrganization): void {
    if (!this.email || !this.role || !organization) {
      return;
    }

    const invitation: Partial<IInvitation> = {
      userEmail: this.email,
      organizationId: organization.id,
      organizationName: organization.name,
      creationTime: this.invitationsApi.getServerTimestamp()
    };

    const organizationUser: Omit<IOrganizationUser, 'id' | 'name' | 'userId'> = {
      email: this.email,
      role: this.role,
      status: EInvitationStatus.PENDING,
      creationTime: this.invitationsApi.getServerTimestamp()
    };

    const email: IEmail = this.emailService.createUserInvitationEmail(organizationUser.email, this.userService.user!.name, organization.name);

    this.isLoading = true;
    this.transactionsApi.inviteUser(invitation, organizationUser, email).then((response) => {
      this.dialogRef.close();
    }).catch((error) => {
      console.error(error);
    }).finally( () => {
      this.isLoading = false;
    });
  }


  setRole(role: EUserRole) {
    this.role = role;
  }

  protected readonly EUserRole = EUserRole;
}

