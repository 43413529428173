<div class="popup column">
  <div class="popup-header column">
    <div class="row">
      <div class="popup-header-title fill-space"> Send Email </div>
      <div class="popup-header-close" mat-dialog-close>
        <img src="assets/images/close.svg" />
      </div>
    </div>
  </div>

  <div class="email-composer popup-content column center">

    <div class="fill-space column gap-1" *ngIf="!isSent">
      <div class="area gap-1">
        <div class="area-label row-center-left"> To </div>
        <div class="recipients-area area-value gap-1 wrap">
          <div class="recipient-email" *ngFor="let r of recipients">
            <div class="recipient-email-text"> {{r.name}} <{{r.email}}> </div>
            <div class="recipient-email-remove"></div>
          </div>
        </div>
      </div>
      <div class="area gap-1">
        <div class="area-label row-center-left"> Subject </div>
        <div class="area-value fill-space"> <input class="fill-space" type="text" [(ngModel)]="subject" /> </div>
      </div>
      <div class="area gap-1 column">
        <div class="area-label"> Message  </div>
        <div class="area-value fill-space">
          <textarea [(ngModel)]="message" class="fill-space" rows="15"></textarea>
        </div>
      </div>
    </div>

    <div class="row fill-space" *ngIf="isSent">
      <div class="email-delivery-progress column center gap-2 fill-space">
        <img src="assets/images/message.gif" />
        <div class="email-delivery-progress-label"> Sending {{emails.length}} Email(s) </div>
        <div class="button button-gray" mat-dialog-close> Close </div>
      </div>
      <div class="email-delivery-recipients overflow-hidden column column-center-left gap-1">
        <div class="email-delivery-recipients-title"> Recipients </div>
        <div class="email-delivery-recipients-content column scrollbar">
          <ng-container *ngFor="let email$ of emails">
            <div class="email-delivery fill-space row row-center-left" *ngIf="email$ | async as email">
              <div class="email-delivery-recipient row-center-left fill-space"> {{ email.to[0].name }} <{{ email.to[0].email }}> </div>
              <div class="email-delivery-status delivery-loader" *ngIf="!email.delivery"> <loader></loader> </div>
              <div class="email-delivery-status" *ngIf="email.delivery?.state === EmailDeliveryStatus.SUCCESS"> <img src="assets/images/tick-green.svg" /> </div>
              <div class="email-delivery-status" *ngIf="email.delivery?.state === EmailDeliveryStatus.ERROR"> <img src="assets/images/email-error.svg" /> </div>
            </div>
          </ng-container>
        </div>
      </div>

    </div>



  </div>

  <div class="popup-buttons row" *ngIf="!isSent">
    <div class="button button-gray" mat-dialog-close> Cancel </div>
    <div class="fill-space"></div>
    <div class="button button-action" (click)="send()">  Send  </div>
  </div>
</div>
