import { Injectable } from "@angular/core";
import {
  collection, doc, Firestore, runTransaction
} from "@angular/fire/firestore";
import { FirebaseCollectionNames } from "../../../../model/FirebaseCollectionNames";
import { StoreService } from "../services/store.service";
import {IInvitation} from "../../../../model/IInvitation";
import {IOrganizationUser} from "../../../../model/IOrganizationUser";
import {IUser} from "../../../../model/IUser";
import {IEmail} from "../../../../model/IEmail";
import {getDocs, query, where} from "@firebase/firestore";

@Injectable({
  providedIn: "root",
})
export class TransactionsApi {

  options: object = { idField: "id" };

  constructor(private firestore: Firestore, private store: StoreService) {}

  async deleteOrganizationUser(organizationUserId: string, organizationUserIdUserId?: string): Promise<void> {

    try {
      await runTransaction(this.firestore, async (transaction) => {
        // Set the references
        const organizationUserReference = doc(collection(this.firestore, this.getOrganizationUsersPath(this.store.organizationId!)), organizationUserId);
        const invitationsCollection = collection(this.firestore, this.getInvitationsPath());

        // Get the organisation user invitations
          const invitationsQuery = query(
              invitationsCollection, where("organizationUserId", "==", organizationUserId),
          );
        const invitationsSnapshot = await getDocs(invitationsQuery);

        // Get the user and check the active organization and their organizations array for occurrences of this.store.organization
        if(organizationUserIdUserId) {
          const userReference = doc(collection(this.firestore, this.getUsersPath()), organizationUserIdUserId);
          const userSnapshot = await transaction.get(userReference);
          if (userSnapshot.exists()) {
            const user: IUser = userSnapshot.data() as IUser;
            if (user.favoriteOrganization === this.store.organizationId!) {
              user.favoriteOrganization = undefined;
            }
            user.organizations = user.organizations.filter(organization => organization !== this.store.organizationId!);
            transaction.update(userReference, {...user});
          }
        }

        // Delete organisation user invitations
        if (!invitationsSnapshot.empty) {
          await Promise.all(
            invitationsSnapshot.docs.map(async (docRef) => {
                const invitationReference = doc(this.firestore, this.getInvitationsPath(), docRef.id);
                transaction.delete(invitationReference);
            }),
          );
        }
        transaction.delete(organizationUserReference);
      });
      console.log("Transaction successfully committed!");
    } catch (e) {
      console.error("Transaction failed: ", e);
      throw e;
    }
  }


  async inviteUser(invitation: Partial<IInvitation>, organizationUser: Partial<IOrganizationUser>, email: IEmail ): Promise<void> {

    try {
      await runTransaction(this.firestore, async (transaction) => {
        // Create the organization user
        const organizationUserReference = doc(collection(this.firestore, this.getOrganizationUsersPath(this.store.organizationId!)));
        transaction.set(organizationUserReference, organizationUser, { merge: true });

        // Create the invitation using the id from the newly created organization user
        const invitationReference = doc(collection(this.firestore, this.getInvitationsPath()));
        transaction.set(invitationReference, {...invitation, organizationUserId: organizationUserReference.id });

        // Create a record in the 'emails' collection so an email can be sent to the invited user.
        const emailReference = doc(collection(this.firestore, this.getEmailsPath()));
        transaction.set(emailReference, email);

      });
      console.log("Transaction successfully committed!");
    } catch (e) {
      console.error("Transaction failed: ", e);
    }
  }

  async acceptInvitation(userId: string, user: Partial<IUser>, invitation: Partial<IInvitation>, organizationUser: Partial<IOrganizationUser>) {

    try {
      await runTransaction(this.firestore, async (transaction) => {
        // References for the documents to be updated
        const userReference = doc(collection(this.firestore, this.getUsersPath()), userId);
        const organizationUserReference = doc(collection(this.firestore, this.getOrganizationUsersPath(invitation.organizationId!)), invitation.organizationUserId);
        const invitationReference = doc( collection( this.firestore, this.getInvitationsPath() ), invitation.id);

        transaction.set(userReference, user, { merge: true });
        transaction.set(organizationUserReference, organizationUser, { merge: true });
        transaction.delete(invitationReference);
      });
      console.log("Transaction successfully committed!");
    } catch (e) {
      console.error("Transaction failed: ", e);
    }
  }


  private getUsersPath() {
    return FirebaseCollectionNames.USERS;
  }
  private getInvitationsPath() {
    return FirebaseCollectionNames.INVITATIONS;
  }

  private getEmailsPath() {
    return FirebaseCollectionNames.EMAILS;
  }

  private getOrganizationUsersPath(organizationId: string) {
    return (FirebaseCollectionNames.ORGANIZATIONS + "/" + organizationId + "/" + FirebaseCollectionNames.USERS);
  }
}
