import { Injectable } from '@angular/core';
import {IEmail, IEmailContact} from "../../../../model/IEmail";
import {EmailsApi} from "../api/emails.api";

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  readonly SUPPORT_EMAIL: string = 'support@hirefox.ai';

  constructor(private emailsApi: EmailsApi) { }

  async sendEmail(email: IEmail)  {
    return this.emailsApi.create(email);
  }

  createPersonalMessageEmail(recipients: IEmailContact[], subject: string, message: string, senderName: string, senderEmail: string): IEmail {

    const toRecipients = recipients;
    /*const personalization = recipientEmails.map(email => ({
      email,
      data: {
        sender_name: senderName,
        sender_email: senderEmail,
        message: message,
        subject: subject
      }
    }));

    let email: IEmail = {
      to: toRecipients,
      from: { name: senderName, email: 'noreply@hirefox.ai'},
      reply_to: { name: senderName, email: senderEmail },
      subject: subject,
      template_id: '3zxk54vw32qljy6v', // The template ID in Mailsender
      personalization: personalization
    }*/
    let email: IEmail = {
      to: recipients,
      from: { name: senderName, email: 'noreply@hirefox.ai'},
      reply_to: { name: senderName, email: senderEmail },
      subject: subject,
      html: message
    }

    console.log(email);

    return email;
  }

  createFreeCreditsNotification(recipientEmail: string, senderName: string,  organizationName: string ): IEmail {
    let email: IEmail = {
      to: [{
        email: recipientEmail
      }],
      template_id: '0p7kx4xv3r7l9yjr', // The template ID in Mailsender
      personalization: [
        {
          email: recipientEmail, // The personalization email ensures that this data will be used only for this recipient
          data: {
            support_email: this.SUPPORT_EMAIL,
            invitation_sender: senderName,
            organization_name: organizationName
          },
        }
      ]
    }
    return email;
  }

  createUserInvitationEmail(recipientEmail: string, senderName: string,  organizationName: string ): IEmail {
    let email: IEmail = {
      to: [{
        email: recipientEmail
      }],
      template_id: '0p7kx4xv3r7l9yjr', // The template ID in Mailsender
      personalization: [
        {
          email: recipientEmail, // The personalization email ensures that this data will be used only for this recipient
          data: {
            support_email: this.SUPPORT_EMAIL,
            invitation_sender: senderName,
            organization_name: organizationName
          },
        }
      ]
    }
    return email;
  }




}
