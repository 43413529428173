<div class="overview-section column">

  <div class="overview-section-title"> Gaps In Employment </div>

  <div class="gaps column">
    <div class="gaps-count">
      <div *ngIf="gaps?.length">
        <div class="gaps-count-icon center">  <img  src="assets/images/gaps-warning.svg"/> </div>
        <div class="gaps-count-text center"> {{gaps.length}} {{ gaps.length > 1 ? 'Gaps' : 'Gap' }} </div>
      </div>
      <div *ngIf="!gaps?.length">
        <div class="gaps-count-icon center"> <img src="assets/images/gaps-check.svg"/>  </div>
        <div class="gaps-count-text center"> No Gaps </div>
      </div>
    </div>
    <div *ngIf="gaps?.length" class="gaps-list column">
      <div class="gap" *ngFor="let g of gaps"> &bull; &nbsp; {{ g }}</div>
    </div>
  </div>

</div>
