<div id="settings-menu" class="column fill-space">
  <!--  <div class="header row space-bottom-1">-->
  <!--    <div class="fill-space column">-->
  <!--      <div class="title">Settings</div>-->
  <!--    </div>-->
  <!--  </div>-->
  <div id="settings-menu-content" class="column gap-1">
    <a class="settings-menu-item" routerLinkActive="settings-menu-item-selected" routerLink="company">
      <div class="column gap-05">
        <div class="settings-menu-item-title">Company</div>
        <div class="settings-menu-item-info">All setting related to this organization and the job portal’s configuration.</div>
      </div>
      <img src="assets/images/arrow-right.svg" alt="" />
    </a>
    <a class="settings-menu-item" routerLinkActive="settings-menu-item-selected" routerLink="subscription">
      <div class="column gap-05">
        <div class="settings-menu-item-title">Subscription</div>
        <div class="settings-menu-item-info">You can modify or cancel your subscription from here.</div>
      </div>
      <img src="assets/images/arrow-right.svg" alt="" />
    </a>
    <a class="settings-menu-item" routerLinkActive="settings-menu-item-selected" routerLink="account">
      <div class="column gap-05">
        <div class="settings-menu-item-title">Account</div>
        <div class="settings-menu-item-info">Account settings</div>
      </div>
      <img src="assets/images/arrow-right.svg" alt="" />
    </a>
    <!--    <a class='settings-menu-item' class="settings-menu-item" routerLinkActive="settings-menu-item-selected" routerLink="invitations"> Invitations </a>-->
    <!--    <a class='settings-menu-item' class="settings-menu-item" routerLinkActive="settings-menu-item-selected" routerLink="companies"> Companies </a>-->
  </div>
<!--  <div class="fill-space"></div>-->
<!--  <div id="settings-menu-logout" class="row row-center-left" (click)="signOut()">-->
<!--    <div id="settings-menu-logout-icon" class="space-right-1">-->
<!--      <img src="assets/images/logout.svg" />-->
<!--    </div>-->
<!--    <div id="settings-menu-logout-text">Logout</div>-->
<!--  </div>-->
</div>
