<div class="credit-plan column gap-2">
  <div class="credit-plan-title">My Credits</div>
  <div class="column gap-1">
    <div class="credit-plan-credits-header row-center-space-between gap-1">
      <div>Active Credits</div>
      <div>{{ availableCredits }} / {{ totalCredits }}</div>
    </div>
    <div class="credit-plan-progress-bar">
      <div class="credit-plan-progress-bar-filled" [style.width.%]="creditsPercentage"></div>
    </div>

    <div class="credit-expiration-container column gap-1" *ngIf="activeCreditsPackages.length">
      <div class="credit-plan-credits-header">Credit Expiration</div>
      <div class="row-center-space-between credit-plan-renewal-header gap-1">
        <div>Number Of Credits</div>
        <div>Expiration Date</div>
      </div>
      <div class="credit-plan-renewal-info column gap-05">
        <div *ngFor="let credit of activeCreditsPackages" class="row-center-space-between gap-1">
          <div>{{ credit.available }}</div>
          <div>
            {{ credit.endDate * 1000 | date: 'd/M/y' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
