import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product } from '../../../../../firestore-stripe-web-sdk/src';
import { Tier } from '../../../../../firestore-stripe-web-sdk/src/product';
import { IPaidSubscription } from '../../../../services/paid-subscriptions.service';

@Component({
  selector: 'subscription-plan-card',
  templateUrl: './subscription-plan-card.component.html',
  styleUrls: ['./subscription-plan-card.component.css']
})
export class SubscriptionPlanCardComponent implements OnInit {
  @Input() plan!: Product;
  @Input() currentPlan!: IPaidSubscription[] | null;
  @Output() selectPlan = new EventEmitter<{ plan: Product; selectedTier: Tier }>();
  selectedTier!: Tier;

  constructor() {}

  ngOnInit(): void {
    // first plan is pre-selected
    this.selectedTier = this.plan.prices[0].tiers[0];
  }

  selectPrice(tier: Tier) {
    this.selectedTier = tier;
    // this.selectedNumber = number;
  }

  getPlanTitle(planName: string | number | null) {
    return typeof planName === 'string' ? planName : '';
  }

  subscribeToPlan() {
    this.selectPlan.emit({ plan: this.plan, selectedTier: this.selectedTier });
  }

  getSubscriptionButtonText() {
    if (this.currentPlan && this.currentPlan.length > 0) {
      if (this.currentPlan[0].credits < this.selectedTier.up_to!) {
        return 'Upgrade plan';
      } else if (this.currentPlan[0].credits === this.selectedTier.up_to!) {
        return 'Subscribe to plan';
      }
    }
    return 'Subscribe to plan';
  }
}
