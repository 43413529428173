import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {IUser, IUserWithRoles} from '../../../../../model/IUser';
import {UserWithOrganizationsService} from '../../services/user-with-organizations.service';
import {AuthService} from '../../services/auth.service';
import {MatDialog} from '@angular/material/dialog';
import {UserOrganizationFormComponent} from './user-organization-form/user-organization-form.component';
import {InvitationsApi} from "../../api/invitations.api";
import {IInvitation} from "../../../../../model/IInvitation";
import {StoreService} from "../../services/store.service";
import {UserService} from "../../services/user.service";
import {IOrganizationUser} from "../../../../../model/IOrganizationUser";
import {TransactionsApi} from "../../api/transactions.api";
import {EInvitationStatus} from "../../../../../model/enums/EInvitationStatus";

@Component({
  selector: 'user-organizations',
  templateUrl: './user-organizations.component.html',
  styleUrls: ['./user-organizations.component.css'],
})
export class UserOrganizationsComponent implements OnInit {
  user$: Observable<IUserWithRoles> | undefined;
  invitations$: Observable<IInvitation[]> | undefined

  constructor(
    private userWithOrganizationsService: UserWithOrganizationsService,
    private authService: AuthService,
    private dialog: MatDialog,
    private invitationsApi: InvitationsApi,
    private store: StoreService,
    private userService: UserService,
    private transactionsApi: TransactionsApi
  ) {}





  showCreateOrganization() {
    let dialog = this.dialog.open(UserOrganizationFormComponent);
  }

  // Using this function to make a full redirect instead of just using
  // [routerLink]="['/organization', organization?.id]" so the organization id can be reloaded.
  changeOrganization(organizationId: string): void {
    window.location.href = `/organization/${organizationId}`;
  }

  signOut() {
    this.authService.signOut();
  }

  ngOnInit() {
    this.user$ = this.userWithOrganizationsService.userWithResolvedOrganizationsWithRole$;
    this.invitations$ = this.invitationsApi.getAll([{ field: 'userEmail', condition: '==', value: this.userService.user!.email! }])
  }

}
