import {Component, OnDestroy, OnInit} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { JobsApi } from "../../../api/jobs.api";
import { Observable, of, switchMap } from "rxjs";
import { IJob } from "../../../../../../model/IJob";
import {JobService} from "../../../services/job.service";
import {ApplicantsService} from "../../../services/applicants.service";

@Component({
  selector: "job",
  templateUrl: "./job.component.html",
  styleUrls: ["./job.component.css"],
})
export class JobComponent implements OnInit, OnDestroy {
  job$: Observable<IJob | undefined> | undefined;

  constructor(
      private route: ActivatedRoute,
      private jobsApi: JobsApi,
      private jobService: JobService,
      private applicantsService: ApplicantsService) {}

  ngOnInit() {
    this.job$ = this.route.paramMap.pipe(
      switchMap((params) => {
        let jobId = params.get("jobId");
        return jobId ? this.jobsApi.getOne(jobId) : of(undefined);
      }),
    );
    this.jobService.job$ = this.job$;
  }

  ngOnDestroy() {
    this.applicantsService.selectedApplicants = [];
  }
}
