import { Component } from '@angular/core';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'settings-menu',
  templateUrl: './settings-menu.component.html',
  styleUrls: ['./settings-menu.component.css']
})
export class SettingsMenuComponent {
  constructor(private auth: AuthService) {}

  signOut(): void {
    this.auth.signOut().catch(() => {
      console.log('Logout Error');
    });
  }
}
