import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { IOrganization } from '../../../../../../../model/IOrganization';
import { IPaidSubscription, PaidSubscriptionsService } from '../../../../services/paid-subscriptions.service';

@Component({
  selector: 'my-subscription',
  templateUrl: './my-subscription.component.html',
  styleUrls: ['./my-subscription.component.css']
})
export class MySubscriptionComponent implements OnInit {
  creditsInitialized$: Observable<boolean> | undefined;
  organization$: Observable<IOrganization | null | undefined> | undefined;

  planName: string = '';
  status: string | null = null;
  renewalDate: string = '';
  pricePerYear: number = 0;
  @Input() currentPlan: IPaidSubscription[] = [];
  @Output() showManageLoader = new EventEmitter<boolean>();

  constructor(private paidSubscriptionsService: PaidSubscriptionsService) {}

  ngOnInit(): void {
    if (this.currentPlan && this.currentPlan[0]) {
      this.planName = this.currentPlan[0].name;
      this.status = this.currentPlan[0].status;
      this.paidSubscriptionsService.creditsProductPriceArray$?.subscribe((tiers) => {
        const activeTier = tiers.find((tier) => tier.up_to === this.currentPlan![0].credits);
        if (activeTier) {
          this.pricePerYear = (activeTier.flat_amount + activeTier.up_to! * activeTier.unit_amount!) / 100;
        }
      });
    } else {
      this.planName = 'Free';
    }

    this.creditsInitialized$ = this.organization$?.pipe(
      filter((organization) => organization !== null && organization !== undefined),
      map((organization) => !!organization!.customerId)
    );
  }

  goToPortal() {
    this.showManageLoader.emit(true);
    this.paidSubscriptionsService.goToOrganizationPortal()?.subscribe();
  }
}
