import { Component, Input, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { IUserInOrganization } from "../../../../../../model/IUser";

import {
  InvitationData,
  OrganizationInvitationsService,
} from "../../../services/organization-invitations.service";
import { Auth } from "@angular/fire/auth";
import { StoreService } from "../../../services/store.service";
import { EUserRole } from "../../../../../../model/enums/EUserRole";
import {OrganizationUsersApi} from "../../../api/organization.users.api";

@Component({
  selector: "user-form",
  templateUrl: "./user-form.component.html",
  styleUrls: ["./user-form.component.css"],
})
export class UserFormComponent implements OnInit {
  @Input() user: IUserInOrganization | undefined;
  isLoading: boolean = false;
  role: EUserRole = EUserRole.MANAGER;

  constructor(private dialogRef: MatDialogRef<UserFormComponent>, private organizationUsers: OrganizationUsersApi) {

  }

  updateUser(): void {
    if (!this.user || !this.role) {
      return;
    }
    let data: Partial<IUserInOrganization> = { role: this.role };
    this.isLoading = true;
    this.organizationUsers.set(this.user.id, data).then((response) => {
        this.dialogRef.close();
      })
      .catch((error) => {
        console.error(error);
        this.isLoading = false;
      });
  }

  setRole(role: EUserRole) {
    this.role = role;
  }

  ngOnInit() {
    if (this.user) {
      this.role = this.user?.role;
    }
  }

  protected readonly EUserRole = EUserRole;
}
