<div class="pricing-card column">
  <div class="pricing-card-title">
    {{ getPlanTitle(plan.name) | titlecase }}
  </div>
  <div *ngIf="plan" class="column">
    <div class="column">
      <div class="credit-tile">Credits</div>
      <div class="gap-05 wrap credits">
        <ng-container *ngFor="let tier of plan.prices[0].tiers">
          <div
            *ngIf='tier.up_to'
            class="credit-number"
            (click)="selectPrice(tier)"
            [ngClass]="{ 'selected-credit-number': selectedTier.up_to === tier.up_to }">
            {{ tier.up_to }}
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="column gap-2">
    <div class="plan-price-container">
      <div class="plan-credits">
        {{ selectedTier.up_to }}
        <div class="plan-credits-year">&nbsp;credits / Year</div>
      </div>
      <div class="plan-price">
        {{ (selectedTier.flat_amount + selectedTier.up_to! * selectedTier.unit_amount!) / 100 }}
        <div class="plan-price-currency">€</div>
      </div>
    </div>
    <ng-container *ngIf="currentPlan">
      <div class="button" (click)="subscribeToPlan()">Subscribe to plan</div>
    </ng-container>
  </div>
</div>
